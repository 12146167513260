<template>
  <div class="m-users-list">
    <b-field grouped group-multiline> </b-field>
    <b-field grouped group-multiline>
      <b-select
        v-model="companyFilter"
        :placeholder="$t('users.company')"
        :loading="loadingCompanies"
        :disabled="loadingCompaniesError"
      >
        <option value="">{{ $t('companies.all') }}</option>
        <option v-for="company in companies" :value="company._id" :key="company._id">{{ company.name }}</option>
      </b-select>

      <div class="control is-flex">
        <b-switch v-model="showDeletedUsers">{{ $t('users.showDeleted') }}</b-switch>
      </div>
    </b-field>
    <b-message v-if="errorDeleteUser" :title="$t('users.deleteError')" type="is-danger">
      {{ deleteUserResponse }}
    </b-message>
    <b-table
      :data="users"
      :paginated="true"
      :per-page="20"
      :loading="loading"
      default-sort="nameLast"
      :row-class="(row, index) => row.state === -1 && 'is-deleted'"
    >
      <b-table-column field="accountTypes" :label="$t('users.accountTypes')" v-slot="props">
        <b-icon v-if="props.row.authProviderLocal" pack="fas" icon="user" />
        <b-icon v-if="props.row.authProviderGoogle" pack="fas" icon="globe" />
      </b-table-column>

      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'user-edit', params: { userId: props.row.id } }">{{ props.row.id }}</router-link>
      </b-table-column>

      <b-table-column field="nameFirst" :label="$t('users.nameFirst')" sortable searchable v-slot="props">
        {{ props.row.nameFirst }}
      </b-table-column>

      <b-table-column field="nameLast" :label="$t('users.nameLast')" sortable searchable v-slot="props">
        {{ props.row.nameLast }}
      </b-table-column>

      <b-table-column field="company" :label="$t('users.company')" sortable searchable v-slot="props">
        {{ props.row.company }}
      </b-table-column>

      <b-table-column field="email" :label="$t('users.email')" sortable searchable v-slot="props">
        {{ props.row.email }}
      </b-table-column>

      <b-table-column :label="$t('users.boarded')" v-slot="props">
        <span>
          <b-icon v-if="props.row.boarded" pack="fas" icon="check" />
        </span>
      </b-table-column>

      <b-table-column field="date" :label="$t('generic.modifiedDate')" sortable centered v-slot="props">
        <span class="tag">
          {{ props.row.modified }}
        </span>
      </b-table-column>

      <b-table-column field="options" :label="$t('generic.options')" centered v-slot="props">
        <b-button
          v-if="props.row.state >= 0"
          type="is-danger"
          icon-right="trash"
          @click="confirmDelete(props.row.id, props.row.type)"
        />
      </b-table-column>
      <template slot="empty">
        <no-data-to-list />
      </template>
    </b-table>
  </div>
</template>
<script>
import * as actions from '../../../store/actions';
import * as namespaces from '../../../store/namespaces';
import logger from '../../../utils/logger';
import NoDataToList from '../_fragments/NoDataToList';

export default {
  name: 'UsersList',
  components: { NoDataToList },
  data() {
    return {
      showDeletedUsers: false,
      isMenuActive: true,
      loading: false,
      loadingCompanies: false,
      loadingCompaniesError: false,
      loadingError: false,
      errorDeleteUser: false,
      deleteUserResponse: '',
      // filters
      companyFilter: '',
      lastNameFilter: ''
    };
  },
  computed: {
    companies() {
      return this.$store.state.companies.all;
    },
    users() {
      let data = [];
      if (this.$store.state.users.all) {
        this.$store.state.users.all.forEach(user => {
          let userCompanyId = user.company ? user.company._id : '';
          if (userCompanyId === this.companyFilter || this.companyFilter === '') {
            if (user.state < 0 && !this.showDeletedUsers) {
              return;
            }
            data.push({
              id: user._id,
              authProviderLocal: user.authProviderLocal,
              authProviderGoogle: user.authProviderGoogle,
              email: user.email,
              nameFirst: user.nameFirst,
              nameLast: user.nameLast,
              company: user.company ? user.company.name : '',
              modified: new Date(user.updatedAt).toLocaleDateString(),
              boarded: user.boarded,
              state: user.state
            });
          }
        });
      }
      return data;
    }
  },
  mounted() {
    this.loading = true;
    this.loadingError = false;
    this.$store
      .dispatch(namespaces.USERS + actions.FETCH_USERS)
      .catch(error => {
        this.loadingError = true;
        logger.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
    this.reloadCompanies();
  },
  methods: {
    confirmDelete(userId) {
      this.deleteUserResponse = '';
      this.errorDeleteUser = false;

      this.$buefy.dialog.confirm({
        message: this.$t('users.confirmDelete'),
        cancelText: this.$t('generic.cancelButton'),
        confirmText: this.$t('generic.yesButton'),
        focusOn: 'cancel',
        onConfirm: () => {
          this.$store
            .dispatch(namespaces.USERS + actions.DELETE_USER, userId)
            .catch(error => {
              this.loadingError = true;
              this.errorDeleteUser = true;
              this.deleteUserResponse = error.response.data.message;
              logger.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    reloadCompanies() {
      this.loadingCompanies = true;
      this.loadingCompaniesError = false;
      this.$store
        .dispatch(namespaces.COMPANIES + actions.FETCH_COMPANIES)
        .catch(error => {
          this.loadingCompaniesError = true;
          logger.error(error);
        })
        .finally(() => {
          this.loadingCompanies = false;
        });
    }
  }
};
</script>
